// CombinedSections.jsx
import React from 'react';
import r3wind from '../media/logo_2_4.png';
import l3gion from '../media/l3gion1x1.png';

function CombinedSections() {
  return (
    <section id="combined-sections" className="section-combined">
        <h2 className="section-title">Media and Community</h2>
      <div className="container">
      <img src={r3wind} alt="R3WIND" className="circular-image" />
            <img src={r3wind} alt="R3WIND" className="circular-image" />
            <img src={r3wind} alt="R3WIND" className="circular-image" />
            <img src={r3wind} alt="R3WIND" className="circular-image" />
            <img src={r3wind} alt="R3WIND" className="circular-image" />
            <img src={r3wind} alt="R3WIND" className="circular-image" />
            <img src={r3wind} alt="R3WIND" className="circular-image" />
            <img src={r3wind} alt="R3WIND" className="circular-image" />
            <img src={l3gion} alt="L3GION" className="circular-image" />
            <img src={l3gion} alt="L3GION" className="circular-image" />
            <img src={l3gion} alt="L3GION" className="circular-image" />
            <img src={l3gion} alt="L3GION" className="circular-image" />
            <img src={l3gion} alt="L3GION" className="circular-image" />
            <img src={l3gion} alt="L3GION" className="circular-image" />
            <img src={l3gion} alt="L3GION" className="circular-image" />
            <img src={l3gion} alt="L3GION" className="circular-image" />

        <div className="columns">

          <div className="column newsletter">
            <h2>Stay Ahead of the Game with <span className='r3wind'>R3wind</span></h2>
            <p>Want a breakdown of EVERYTHING exciting that happened in Crypto Gaming this week? Missed anything? Subscribe to R3WIND so you don't!</p>
            <a href="https://r3wind.beehiiv.com/subscribe" className="btn btn-primary" target="_blank" rel="noopener noreferrer">Join R3WIND Newsletter</a>

          </div>
          <div className="column discord">
            <h2>Join the Discord Community <span className='l3gion'>L3gion</span> </h2>
            <p>Alpha, Whitelists, Game Nights, Research. If you're in Crypto Gaming & not in L3GION you're missing out.</p>

            <a href="http://www.l3gion.gg/" className="btn btn-secondary" target="_blank" rel="noopener noreferrer">Join L3GION Discord</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CombinedSections;
